"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateChapterItem = exports.generateSectionItem = void 0;
// @ts-ignore
const utils_js_1 = require("@/utils/utils.js");
const defaultChapterName = '本章名称';
const defaultSectionName = '本节名称';
function generateSectionItem({ name, content, scorePointIDList } = {}) {
    return {
        key: `2-${(0, utils_js_1.generateItemKeyIndex)()}`,
        title: name || defaultSectionName,
        section_title: name || defaultSectionName,
        // sort: 1,
        introduce: content || '<p><br></p>',
        scoring_points: scorePointIDList || []
    };
}
exports.generateSectionItem = generateSectionItem;
function generateChapterItem({ name, sectionList } = {}) {
    return {
        key: `1-${(0, utils_js_1.generateItemKeyIndex)()}`,
        title: name || defaultChapterName,
        // sort: 1,
        section_list: Array.isArray(sectionList) ? sectionList.map(item => generateSectionItem(item)) : [generateSectionItem({})],
        section: []
    };
}
exports.generateChapterItem = generateChapterItem;
